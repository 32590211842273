ul.links {
  margin: 0;
  padding: 0;
}
ul.links li {
  display: inline;
  list-style: none;
  padding: 0 10px 0 0;
}
ul.links.inline {
  display: block;
}

.nav {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.nav:before, .nav:after {
  display: table;
  content: " ";
}
.nav:after {
  clear: both;
}
.nav > li {
  position: relative;
  display: block;
}
.nav > li > a {
  position: relative;
  display: block;
  padding: 10px 15px;
}
.nav > li > a:hover, .nav > li > a:focus {
  text-decoration: none;
  background-color: white;
}
.nav > li.disabled > a {
  color: #cccccc;
}
.nav > li.disabled > a:hover, .nav > li.disabled > a:focus {
  color: #cccccc;
  text-decoration: none;
  cursor: not-allowed;
  background-color: transparent;
}
.nav .open > a, .nav .open > a:hover, .nav .open > a:focus {
  background-color: white;
  border-color: #222;
}
.nav .nav-divider {
  height: 1px;
  margin: 10px 0;
  overflow: hidden;
  background-color: #e5e5e5;
}
.nav > li > a > img {
  max-width: none;
}

.nav-tabs {
  border-bottom: 1px solid #ddd;
}
.nav-tabs > li {
  float: left;
  margin-bottom: -1px;
}
.nav-tabs > li > a {
  margin-right: 2px;
  line-height: 1.428571429;
  border: 1px solid transparent;
  border-radius: 8px 8px 0 0;
}
.nav-tabs > li > a:hover {
  border-color: white white #ddd;
}
.nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus {
  color: #aaaaaa;
  cursor: default;
  background-color: #fff;
  border: 1px solid #ddd;
  border-bottom-color: transparent;
}
.nav-pills > li {
  float: left;
}
.nav-pills > li > a {
  border-radius: 8px;
}
.nav-pills > li + li {
  margin-left: 2px;
}
.nav-pills > li.active > a, .nav-pills > li.active > a:hover, .nav-pills > li.active > a:focus {
  color: #fff;
  background-color: #017630;
}

.nav-stacked > li {
  float: none;
}
.nav-stacked > li + li {
  margin-top: 2px;
  margin-left: 0;
}

.nav-justified, .nav-tabs.nav-justified {
  width: 100%;
}
.nav-justified > li, .nav-tabs.nav-justified > li {
  float: none;
}
.nav-justified > li > a, .nav-tabs.nav-justified > li > a {
  margin-bottom: 5px;
  text-align: center;
}
.nav-justified > .dropdown .dropdown-menu {
  top: auto;
  left: auto;
}
@media (min-width: 768px) {
  .nav-justified > li, .nav-tabs.nav-justified > li {
    display: table-cell;
    width: 1%;
  }
  .nav-justified > li > a, .nav-tabs.nav-justified > li > a {
    margin-bottom: 0;
  }
}

.nav-tabs-justified, .nav-tabs.nav-justified {
  border-bottom: 0;
}
.nav-tabs-justified > li > a, .nav-tabs.nav-justified > li > a {
  margin-right: 0;
  border-radius: 8px;
}
.nav-tabs-justified > .active > a, .nav-tabs.nav-justified > .active > a,
.nav-tabs-justified > .active > a:hover,
.nav-tabs-justified > .active > a:focus {
  border: 1px solid #ddd;
}
@media (min-width: 768px) {
  .nav-tabs-justified > li > a, .nav-tabs.nav-justified > li > a {
    border-bottom: 1px solid #ddd;
    border-radius: 8px 8px 0 0;
  }
  .nav-tabs-justified > .active > a, .nav-tabs.nav-justified > .active > a,
  .nav-tabs-justified > .active > a:hover,
  .nav-tabs-justified > .active > a:focus {
    border-bottom-color: #cccccc;
  }
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.breadcrumb {
  margin-bottom: 0.5em;
  margin-top: 0;
}
.breadcrumb ol {
  display: inline;
  margin: 0;
  padding: 0;
}
.breadcrumb ol li {
  list-style: none;
  display: inline;
}
.breadcrumb .breadcrumb-label {
  font-size: 1em;
  display: inline;
  padding-right: 10px;
}
.breadcrumb .breadcrumb-label::after {
  content: ":";
}

.breadcrumb-seperator {
  color: #888888;
  font-size: 0.9em;
  padding: 0 5px;
}

.menu {
  margin: 0;
  padding: 0;
}
.menu li {
  list-style: none;
  display: block;
  position: relative;
}
.menu li:last-child {
  border-bottom: 0 none;
}
.menu li a {
  display: block;
  text-decoration: none;
  padding: 9px 15px;
  outline: 0;
  line-height: normal;
}
.menu::after {
  clear: both;
  content: " ";
  display: table;
  visibility: hidden;
}

.webks-menu-hover .expanded:hover > a {
  background-color: #01672a;
  color: #fff !important;
}
.webks-menu-hover .expanded:hover > .menu {
  border-top: 2px solid #01672a;
  display: block;
}
.webks-menu-hover .expanded > .menu {
  display: none;
}
.webks-menu-dropdown .menu .menu-item--right .menu {
  left: auto;
  right: 0;
}
.webks-menu-dropdown .menu .menu-item--right .menu .menu {
  left: auto;
  right: 100%;
}
.webks-menu-dropdown .menu .menu {
  display: none;
  top: 100%;
  left: 0;
  position: absolute;
  z-index: 99;
  background: rgba(0, 0, 0, 0.85);
  color: #fff;
  padding: 0 0;
  width: 100%;
  min-width: 320px;
  max-width: 100%;
}
.webks-menu-dropdown .menu .menu li {
  border-color: rgba(0, 0, 0, 0.85);
}
.webks-menu-dropdown .menu .menu a {
  color: #fff;
}
.webks-menu-dropdown .menu .menu a {
  border-bottom: 1px dotted #014e20;
}
.webks-menu-dropdown .menu .menu a:hover {
  background-color: #017630;
  color: #fff;
}
.webks-menu-dropdown .menu .menu .btn-expandable-menu-item--closer {
  background-color: rgba(0, 0, 0, 0.85);
}
.webks-menu-dropdown .menu .menu .menu {
  background-color: rgba(0, 0, 0, 0.85);
}
.webks-menu-dropdown .menu .menu .menu .btn-expandable-menu-item--closer {
  background-color: rgba(0, 0, 0, 0.85);
}
.webks-menu-dropdown .menu .menu .menu .menu {
  background-color: rgba(0, 0, 0, 0.85);
}
.webks-menu-dropdown .menu .menu .menu .menu .btn-expandable-menu-item--closer {
  background-color: rgba(0, 0, 0, 0.85);
}
.webks-menu-dropdown .menu .menu .menu .menu .menu {
  background-color: rgba(0, 0, 0, 0.85);
}
.webks-menu-dropdown .menu .expanded.open {
  z-index: 9999;
}
.webks-menu-dropdown .menu .expanded.open > .menu {
  display: block;
}
.webks-menu-dropdown .menu .expanded:not(.expandable-menu-item).open > a {
  background-color: rgba(0, 0, 0, 0.85);
  color: #fff;
}

.webks-menu-dropdown--horizontal .menu .menu, .webks-menu-dropdown .menu .menu .menu {
  left: 100%;
  top: 0;
  bottom: auto;
  right: auto;
}

.expandable-menu-item > .btn-expandable-menu-item {
  width: 40px;
  height: 100%;
  max-height: 44px;
  display: block;
  text-align: center;
  position: absolute;
  right: 0;
  top: 0;
  border-left: 1px solid rgba(0, 0, 0, 0.85);
  cursor: pointer;
  text-indent: -9000px;
}
.expandable-menu-item > .btn-expandable-menu-item--opener::before {
  font-family: "iconfont";
  content: "\edc2";
}
.expandable-menu-item > .btn-expandable-menu-item--closer::before {
  font-family: "iconfont";
  content: "\edba";
}
.expandable-menu-item > .btn-expandable-menu-item::before {
  width: 40px;
  height: 40px;
  font-family: "iconfont";
  text-indent: 0;
  float: left;
  border-radius: 0;
  line-height: 40px;
}
.expandable-menu-item > a {
  margin-right: 40px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.expandable-menu-item > .menu {
  display: none;
}
.expandable-menu-item.open > .menu {
  display: block;
}

.region-navigation-bar {
  display: none;
}
@media (min-width: 992px) {
  .region-navigation-bar {
    display: block;
  }
}
.region-navigation-bar .block-menu-block {
  width: 100%;
}

.navigation-bar-wrapper .menu-block-wrapper > .menu {
  display: flex;
  justify-content: space-between;
}
@media (min-width: 992px) {
  .navigation-bar-wrapper .menu-block-wrapper > .menu {
    margin-left: -8px;
    margin-right: -8px;
  }
  .navigation-bar-wrapper .menu-block-wrapper > .menu > li {
    float: left;
    border-bottom: 0 none;
  }
  .navigation-bar-wrapper .menu-block-wrapper > .menu > li.last {
    border-right: 0;
  }
  .navigation-bar-wrapper .menu-block-wrapper > .menu > li > a {
    padding-left: 8px;
    padding-right: 8px;
  }
}
.navigation-bar-wrapper .menu-block-wrapper > .menu > li {
  text-align: left;
}
.navigation-bar-wrapper .menu-block-wrapper > .menu > li > a {
  color: #fff;
  font-size: 16px;
  padding: 15px 15px;
}
.navigation-bar-wrapper .menu-block-wrapper > .menu > li > a.active, .navigation-bar-wrapper .menu-block-wrapper > .menu > li > a.active-trail {
  color: #fff;
  font-weight: 600;
}

@media (max-width: 991px) {
  body {
    padding-top: 60px !important;
  }
}
@media (max-width: 991px) and (min-width: 992px) {
  body {
    padding-top: 75px !important;
  }
}

@media (max-width: 1099px) {
  .headroom {
    transform: translateY(0%);
    top: 0;
    display: block;
  }
}
.headroom--unpinned {
  transform: translateY(-100%);
  margin-top: -1px;
}
.headroom--pinned {
  transform: translateY(0%);
  top: 0;
  display: block;
}

.drowl-headroom-wrapper {
  background: #017630;
  max-width: 100vw;
  border-bottom: 1px solid #01672a;
  width: 100% !important;
  padding: 0 !important;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  -webkit-transition: transform 300ms linear;
  -o-transition: transform 300ms linear;
  transition: transform 300ms linear;
}
@media (min-width: 992px) {
  .drowl-headroom-wrapper {
    display: none;
  }
}
@media (min-width: 992px) {
  .drowl-headroom-wrapper--invisible {
    display: block;
    transform: translateY(-100%);
    margin-top: -1px;
  }
}
.drowl-headroom-wrapper:not(.drowl-headroom-wrapper--invisible) {
  display: block;
}
.drowl-headroom-wrapper > .region-inner {
  max-width: 1100px;
  margin: 0 auto;
  padding: 5px 0;
  display: table;
  display: flex;
  justify-content: space-between;
}
@media (max-width: 1099px) {
  .drowl-headroom-wrapper > .region-inner {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.drowl-headroom-wrapper > .region-inner > .block {
  display: table-cell;
  vertical-align: top;
}
.drowl-headroom-wrapper > .region-inner > .block:last-child {
  margin-left: auto;
}
.drowl-headroom-wrapper > .region-inner .block--navbar-logo {
  max-width: 120px;
}
.drowl-headroom-wrapper .block-menu-block {
  width: 100%;
}
.drowl-headroom-wrapper .menu-block-wrapper > .menu {
  display: flex;
  justify-content: space-between;
}
@media (min-width: 992px) {
  .drowl-headroom-wrapper .menu-block-wrapper > .menu {
    margin-left: -8px;
    margin-right: -8px;
  }
  .drowl-headroom-wrapper .menu-block-wrapper > .menu > li {
    float: left;
    border-bottom: 0 none;
  }
  .drowl-headroom-wrapper .menu-block-wrapper > .menu > li.last {
    border-right: 0;
  }
  .drowl-headroom-wrapper .menu-block-wrapper > .menu > li > a {
    padding-left: 8px;
    padding-right: 8px;
  }
}
.drowl-headroom-wrapper .menu-block-wrapper > .menu > li {
  text-align: left;
}
.drowl-headroom-wrapper .menu-block-wrapper > .menu > li > a {
  color: #fff;
  font-size: 16px;
  padding: 15px 15px;
}
.drowl-headroom-wrapper .menu-block-wrapper > .menu > li > a.active, .drowl-headroom-wrapper .menu-block-wrapper > .menu > li > a.active-trail {
  color: #fff;
  font-weight: 600;
}
.drowl-headroom-wrapper .block {
  margin: 0;
}

.webks-offcanvas-layer-opened .drowl-headroom-wrapper {
  transform: translateY(-100%);
  margin-top: -1px;
}

.block-menu-menu-footer-menu .menu li {
  float: none;
  display: inline-block;
  margin-right: 15px;
}
.block-menu-menu-footer-menu .menu li:last-child {
  margin-right: 0;
}
.block-menu-menu-footer-menu .menu li a {
  padding: 2px 0;
}

button.webks-offcanvas-trigger {
  background-color: transparent;
  border-color: #cccccc;
  color: #fff;
}
button.webks-offcanvas-trigger:hover {
  background-color: transparent;
  border-color: #d9d9d9;
  color: #fff;
}
button.webks-offcanvas-trigger:active, button.webks-offcanvas-trigger:focus {
  background-color: transparent;
  border-color: #bfbfbf;
  color: #fff;
}
button.webks-offcanvas-trigger .ico-seperate {
  margin-right: 0;
  width: 18px;
}
button.webks-offcanvas-trigger .ico-seperate::before {
  font-size: 18px;
}
button.webks-offcanvas-trigger .btn-label {
  display: none;
}

.webks-offcanvas-layer {
  display: none;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10000;
  height: 100%;
  max-height: none !important;
  width: 90%;
  background-color: rgba(0, 0, 0, 0.85);
  overflow: auto;
}
.webks-offcanvas-layer.open {
  display: block;
  animation-duration: 250ms;
  animation-fill-mode: both;
  animation-name: fadeInRight;
}
.webks-offcanvas-layer .btn-expandable-menu-item--closer {
  background-color: rgba(0, 0, 0, 0.85);
}
.webks-offcanvas-layer .menu {
  position: static !important;
}
.webks-offcanvas-layer .menu .menu {
  background-color: rgba(0, 0, 0, 0.85);
}
.webks-offcanvas-layer .menu .menu .btn-expandable-menu-item--closer {
  background-color: rgba(0, 0, 0, 0.85);
}
.webks-offcanvas-layer .menu .menu .menu {
  background-color: rgba(0, 0, 0, 0.85);
}
.webks-offcanvas-layer .menu .menu .menu .btn-expandable-menu-item--closer {
  background-color: rgba(0, 0, 0, 0.85);
}
.webks-offcanvas-layer .menu .menu .menu .menu {
  background-color: rgba(0, 0, 0, 0.85);
}
.webks-offcanvas-layer li {
  border-bottom: 1px solid rgba(0, 0, 0, 0.85);
  font-size: 16px;
  line-height: 20px;
}
.webks-offcanvas-layer li a {
  color: #fff;
  padding: 10px 20px;
}
.webks-offcanvas-layer li.expanded.open > a {
  border-bottom: 1px solid rgba(0, 0, 0, 0.85);
}
.webks-offcanvas-layer .expandable-menu-item > a {
  margin-right: 40px;
}
.webks-offcanvas-layer .expandable-menu-item > .btn-expandable-menu-item {
  width: 40px;
  max-height: 40px;
}
.webks-offcanvas-layer .expandable-menu-item > .btn-expandable-menu-item::before {
  width: 40px;
  height: 40px;
  line-height: 40px;
}
.webks-offcanvas-layer .menu-mlid-close {
  display: none;
}
.webks-offcanvas-layer .block-search-form {
  border-bottom: 1px solid rgba(0, 0, 0, 0.85);
  margin-bottom: 5px;
  padding: 10px 60px 10px 20px !important;
  float: none !important;
  width: 100% !important;
}
.webks-offcanvas-layer .block-search-form .form-submit {
  background-color: rgba(0, 0, 0, 0.85) !important;
}
.webks-offcanvas-layer .block-search-form + .block-menu-block {
  padding-top: 0;
}
.webks-offcanvas-layer .block-menu-block {
  padding-top: 55px;
}
.webks-offcanvas-layer .block--social-media {
  margin: 15px 15px 0 15px;
  text-align: center;
  padding: 15px 15px;
  background: #fff;
  border-radius: 8px;
}
.webks-offcanvas-layer .block--social-media .ico-seperate {
  float: none;
  display: inline-block;
}

button.drowl-offcanvas__close-btn {
  border-width: 1px;
  border-style: solid;
  display: inline-block;
  font-weight: 300;
  cursor: pointer;
  text-align: left;
  text-decoration: none;
  vertical-align: top;
  user-select: none;
  background-color: transparent;
  border-color: #cccccc;
  color: #fff;
  padding: 15px 20px;
  border-radius: 5px;
  line-height: 18px;
  font-size: 16px;
  text-indent: -9000px;
  position: relative;
  padding: 0;
  min-width: 58px;
  line-height: 48px;
  position: absolute;
  right: 7px;
  top: 10px;
  z-index: 3;
  background-color: rgba(0, 0, 0, 0.85) !important;
}
button.drowl-offcanvas__close-btn:hover {
  background-color: transparent;
  border-color: #d9d9d9;
  color: #fff;
}
button.drowl-offcanvas__close-btn:active, button.drowl-offcanvas__close-btn:focus {
  background-color: transparent;
  border-color: #bfbfbf;
  color: #fff;
}
button.drowl-offcanvas__close-btn::before {
  text-indent: 0;
  float: left;
  vertical-align: middle;
  width: 100%;
  text-align: center;
  display: inline-block;
  font-family: "iconfont";
  font-style: normal;
  font-weight: normal;
  margin-right: 5px;
  text-align: center;
  content: "\ed6e";
}
button.drowl-offcanvas__close-btn:hover {
  text-decoration: none;
}

@media (min-width: 768px) {
  .shrinked-main-menu-triggers li a {
    text-indent: 0;
  }
  .shrinked-main-menu-triggers li a::before {
    display: inline-block;
    position: static;
    margin-right: 5px;
    margin-bottom: -5px;
    width: auto;
  }
}
.pager {
  clear: both;
  margin: 1rem 0 0 0 !important;
  text-align: center;
  list-style-type: none;
  padding: 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
@media only screen and (min-width:992px) {
  .pager {
    flex-wrap: nowrap;
  }
}
.pager .ajax-progress {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -8px;
  margin-top: -8px;
}
.pager li {
  margin: 0 5px 5px 0;
  display: inline-block;
  position: relative;
}
.pager li a {
  display: block;
}
.pager li:last-child {
  margin-right: 0;
}
.pager .pager-current {
  padding: 3px 5px;
}
.pager .pager-previous {
  margin-right: auto;
  flex-grow: 1;
}
@media only screen and (min-width:992px) {
  .pager .pager-previous {
    flex-grow: 0;
  }
}
.pager .pager-next {
  margin-left: auto;
  flex-grow: 1;
}
@media only screen and (min-width:992px) {
  .pager .pager-next {
    flex-grow: 0;
  }
}
.pager .pager-first,
.pager .pager-last {
  flex-grow: 1;
}
@media only screen and (min-width:992px) {
  .pager .pager-first,
  .pager .pager-last {
    flex-grow: 0;
  }
}
.pager a,
.pager .pager-current {
  border-width: 1px;
  border-style: solid;
  display: inline-block;
  font-weight: 300;
  cursor: pointer;
  text-align: left;
  text-decoration: none;
  vertical-align: top;
  user-select: none;
  background-color: transparent;
  border-color: #aaaaaa;
  color: #222;
  padding: 15px 20px;
  border-radius: 5px;
  line-height: 18px;
  font-size: 16px;
  line-height: 1.05em;
}
.pager a:hover,
.pager .pager-current:hover {
  background-color: transparent;
  border-color: #bfbfbf;
  color: #017630;
}
.pager a:active, .pager a:focus,
.pager .pager-current:active,
.pager .pager-current:focus {
  background-color: transparent;
  border-color: #9e9e9e;
  color: #222;
}
.pager a::before,
.pager .pager-current::before {
  line-height: 1;
}
.pager a:hover,
.pager .pager-current:hover {
  text-decoration: none;
}

.nav-tabs {
  position: relative;
}

ul.primary {
  margin-bottom: 15px;
}
ul.primary a {
  text-decoration: none;
}

#login-links,
.ui-tabs .ui-tabs-nav,
.horizontal-tabs-list {
  border-color: #cccccc;
  border-width: 0 0 1px;
  border-style: solid;
  border-radius: 0;
  position: relative;
  padding: 0;
  list-style: none;
  background: none;
  margin: 0.5em 0 1em;
  line-height: normal;
}
#login-links::after,
.ui-tabs .ui-tabs-nav::after,
.horizontal-tabs-list::after {
  content: " ";
  width: 15px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, #fff 100%);
}
@media (max-width: 1099px) {
  #login-links,
  .ui-tabs .ui-tabs-nav,
  .horizontal-tabs-list {
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
  }
}
#login-links li,
.ui-tabs .ui-tabs-nav li,
.horizontal-tabs-list li {
  border: 0 none;
  background: none;
  color: #222;
  float: none;
  display: inline-block;
  margin-right: 3px;
}
#login-links li a.active,
#login-links li a.lt-active, #login-links li.selected a, #login-links li.ui-tabs-active > a,
.ui-tabs .ui-tabs-nav li a.active,
.ui-tabs .ui-tabs-nav li a.lt-active,
.ui-tabs .ui-tabs-nav li.selected a,
.ui-tabs .ui-tabs-nav li.ui-tabs-active > a,
.horizontal-tabs-list li a.active,
.horizontal-tabs-list li a.lt-active,
.horizontal-tabs-list li.selected a,
.horizontal-tabs-list li.ui-tabs-active > a {
  position: relative;
  bottom: -1px;
  padding-top: 8px;
  color: #017630;
  background: #fff;
}
#login-links li a,
.ui-tabs .ui-tabs-nav li a,
.horizontal-tabs-list li a {
  display: block;
  padding: 7px 10px;
  text-decoration: none;
  outline: 0;
  border-width: 1px 1px 0;
  border-style: solid;
  border-color: #cccccc;
  border-radius: 0;
  background: #cccccc;
}
#login-links li a strong,
.ui-tabs .ui-tabs-nav li a strong,
.horizontal-tabs-list li a strong {
  font-weight: normal;
}
#login-links li a:hover, #login-links li a:focus,
.ui-tabs .ui-tabs-nav li a:hover,
.ui-tabs .ui-tabs-nav li a:focus,
.horizontal-tabs-list li a:hover,
.horizontal-tabs-list li a:focus {
  color: #222;
  background: #fff;
}
#login-links li a:focus,
.ui-tabs .ui-tabs-nav li a:focus,
.horizontal-tabs-list li a:focus {
  outline: 0;
  border-color: #b8b8b8;
}

.horizontal-tabs-panes {
  clear: both;
  padding: 10px 15px;
  background: #fff;
  border: 1px solid #aaaaaa;
}
.horizontal-tabs-panes .field-group-htab {
  padding: 0;
}
.horizontal-tabs-panes .horizontal-tabs-pane > legend {
  display: none !important;
}
.horizontal-tabs-panes .horizontal-tabs-pane > .fieldset-wrapper {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.horizontal-tabs-panes .horizontal-tab-hidden {
  display: none;
}

ul.secondary {
  margin: 0 0 8px;
  padding: 0.55em 10px;
  border-width: 0 1px 1px;
  border-style: solid;
  line-height: normal;
  border-color: #cccccc;
  background: #fff;
}
ul.secondary::after {
  clear: both;
  content: " ";
  display: table;
  visibility: hidden;
}
ul.secondary li {
  border-color: #cccccc;
  float: left;
  margin-right: 7px;
  padding-right: 7px;
  list-style: none;
  border-width: 0 1px 0 0;
  border-style: solid;
}
ul.secondary li:last-child {
  border-width: 0;
}
ul.secondary li a {
  display: block;
  text-decoration: none;
}

div.vertical-tabs {
  margin: 1em 0;
  width: 100%;
  border: 0 none;
  box-sizing: border-box;
}
div.vertical-tabs ul.vertical-tabs-list {
  margin: 0;
  padding: 0;
  list-style: none;
  border-width: 0 3px 0 0;
  border-style: solid;
  border-color: #017630;
  box-sizing: border-box;
}
@media (min-width: 768px) {
  div.vertical-tabs ul.vertical-tabs-list {
    width: 30%;
    float: left;
  }
}
div.vertical-tabs ul.vertical-tabs-list li {
  border: 0 none;
  margin-bottom: 2px;
}
div.vertical-tabs ul.vertical-tabs-list li a {
  color: #222;
  text-decoration: none;
  display: block;
  padding: 10px;
  line-height: 1.1em;
  border-width: 1px 0 1px 1px;
  border-style: solid;
  border-color: #fff;
  background-color: #fff;
}
div.vertical-tabs ul.vertical-tabs-list li a .form-required {
  color: #fff;
}
div.vertical-tabs ul.vertical-tabs-list li a strong {
  font-weight: normal;
}
div.vertical-tabs ul.vertical-tabs-list li a .summary {
  display: block;
  color: #aaaaaa;
}
div.vertical-tabs ul.vertical-tabs-list li a:hover {
  outline: 0;
}
div.vertical-tabs ul.vertical-tabs-list li:hover {
  border-color: #cccccc;
  background: #fff;
}
div.vertical-tabs ul.vertical-tabs-list li.selected a {
  background: #017630;
  border-color: #017630;
  border-radius: 8px 0 0 8px;
}
div.vertical-tabs ul.vertical-tabs-list li.selected a .summary {
  color: #2cfd80;
}
div.vertical-tabs ul.vertical-tabs-list li.selected strong {
  color: #fff;
}
div.vertical-tabs .vertical-tabs-panes {
  clear: both;
  border-width: 0 0 3px;
  border-style: solid;
  border-color: #017630;
  margin-left: -3px;
  box-sizing: border-box;
}
@media (min-width: 768px) {
  div.vertical-tabs .vertical-tabs-panes {
    clear: none;
    float: left;
    width: 70%;
    border-width: 0 0 0 3px;
  }
}
div.vertical-tabs .vertical-tabs-pane {
  margin: 0;
  border: 0 none;
  box-sizing: border-box;
}
div.vertical-tabs .vertical-tabs-pane > legend {
  display: none;
}

ul.action-links {
  margin: 10px 0 30px;
  padding: 0;
  list-style: none;
}
ul.action-links > li > a {
  border-width: 1px;
  border-style: solid;
  display: inline-block;
  font-weight: 300;
  cursor: pointer;
  text-align: left;
  text-decoration: none;
  vertical-align: top;
  user-select: none;
  background-color: transparent;
  border-color: #aaaaaa;
  color: #222;
  padding: 8px 10px;
  border-radius: 5px;
  line-height: 1em;
  font-size: 12px;
  line-height: 1.05em;
}
ul.action-links > li > a:hover {
  background-color: transparent;
  border-color: #bfbfbf;
  color: #017630;
}
ul.action-links > li > a:active, ul.action-links > li > a:focus {
  background-color: transparent;
  border-color: #9e9e9e;
  color: #222;
}
ul.action-links > li > a::before {
  line-height: 1;
}
ul.action-links > li > a:hover {
  text-decoration: none;
}

.ui-tabs {
  border: 0 none;
}