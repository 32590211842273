// _base.scss imports _custom.scss (variables and mixins) and other partials.
@import "base";

// Drupal Node Links
ul.links {
  margin: 0;
  padding: 0;
  li {
    display: inline;
    list-style: none;
    padding: 0 10px 0 0;
  }
  &.inline {
    display: block; // why?
  }
}

// =============================================================================
//  Bootstrap Navigation things (included from libraries/_bootstrap)
// =============================================================================

@import "libraries/bootstrap-sass/assets/stylesheets/bootstrap/navs";

// =============================================================================
//  Breadcrumbs
// =============================================================================
.breadcrumb {
  margin-bottom: 0.5em;
  margin-top: 0; // no spacing top - set this at the outer wrapper, it has to fit the vertical grid from the right / left sidebar!
  ol {
    display: inline;
    margin: 0;
    padding: 0;
    li {
      list-style: none;
      display: inline;
    }
  }
  .breadcrumb-label {
    font-size: 1em;
    display: inline;
    padding-right: 10px;
    &::after {
      content: ":";
    }
  }
}
.breadcrumb-seperator {
  color: $grey-dark;
  font-size: 0.9em;
  padding: 0 5px;
}

// =============================================================================
//  Menus
// =============================================================================

// Basics
.menu {
  margin: 0;
  padding: 0;
  li {
    list-style: none;
    display: block;
    position: relative;
    &:last-child {
      border-bottom: 0 none;
    }
    a {
      display: block;
      text-decoration: none;
      padding: 9px $grid / 2;
      outline: 0;
      line-height: normal;
    }
  }
  @include clearfix-after;
}

// Horizontal
@mixin menu--horizontal {
  // Attiontion: Defining the breakpoint has to be located in the block styling (caus we cant determine here, how long the menu is and in wich content it exists)
  margin-left: ceil($thin-grid / 2) * -1;
  margin-right: ceil($thin-grid / 2) * -1;
  > li {
    float: left;
    border-bottom: 0 none;
    &.last {
      border-right: 0;
    }
    > a {
      padding-left: ceil($thin-grid / 2);
      padding-right: ceil($thin-grid / 2);
    }
  }
}

// Vertical (for overriding)
@mixin menu--vertical {
  > li {
    float: none;
    border-right: 0 none;
    border-bottom: 1px solid $grey-light;
  }
}

// Dropdown
%menu--hover {
  // We need this to have the ability, to remove the hover class, to get a proper touch menu
  .expanded {
    &:hover {
      > a {
        background-color: $menu-flyout-trigger-link-hover-bg;
        color: $menu-flyout-trigger-link-hover-color !important;
      }
      > .menu {
        border-top: 2px solid $menu-flyout-stripe-color;
        display: block;
      }
    }
    // Add Arrow Icons
    &:not(.expandable-menu-item) {
      > a {
        @if $main-menu-enable-expanded-arrows == true {
          display: flex;
          align-items: center;
          justify-content: space-between;
          &::after {
            @include get-ico-only("arrow-thin-bottom");
            font-family: $iconfont;
            font-size: $icon-size-xxs;
            margin-left: 5px;
            vertical-align: middle;
            transition-duration: 0ms;
          }
        }
      }
    }
    > .menu {
      // Deeper menu layers
      display: none;
      .expanded:not(.expandable-menu-item) {
        @if $main-menu-enable-expanded-arrows == true {
          > a::after {
            @include get-ico-only("arrow-thin-right");
            float: right;
          }
        }
      }
    }
  }
}

$dropdown-depth-1-color: darken($menu-flyout-background, 8%);
$dropdown-depth-2-color: darken($menu-flyout-background, 12%);
$dropdown-depth-3-color: darken($menu-flyout-background, 18%);
%menu--dropdown {
  .menu-item--right {
    // Switch right located flyouts to the left
    .menu {
      left: auto;
      right: 0;
      .menu {
        left: auto;
        right: 100%;
      }
    }
  }
  .menu {
    display: none;
    top: 100%;
    left: 0;
    @include menu-flyout;
    a {
      border-bottom: $menu-link-border-bottom;
      &:hover {
        background-color: $menu-link-hover-bg;
        color: $menu-link-hover-text;
      }
    }
    .btn-expandable-menu-item--closer {
      background-color: $dropdown-depth-1-color;
    }
    .menu {
      // Depth 1
      background-color: $dropdown-depth-1-color;
      .btn-expandable-menu-item--closer {
        background-color: $dropdown-depth-2-color;
      }
      .menu {
        // Depth 2
        background-color: $dropdown-depth-2-color;
        .btn-expandable-menu-item--closer {
          background-color: $dropdown-depth-3-color;
        }
        .menu {
          // Depth 3
          background-color: $dropdown-depth-3-color;
        }
      }
    }
  }
  .expanded {
    &.open {
      z-index: 9999;
      > .menu {
        display: block;
      }
    }
  }
  .expanded:not(.expandable-menu-item) {
    &.open {
      > a {
        background-color: $menu-flyout-background;
        color: $menu-flyout-color;
      }
    }
  }
}
%menu--dropdown-horizontal {
  // Secondary Menu Flyouts opens right, instead of bottom
  .menu {
    left: 100%;
    top: 0;
    bottom: auto;
    right: auto;
  }
}

// Expandable Menu Items (touch support)
.expandable-menu-item {
  > .btn-expandable-menu-item {
    width: 40px;
    height: 100%;
    max-height: 44px;
    display: block;
    text-align: center;
    position: absolute;
    right: 0;
    top: 0;
    border-left: 1px solid $menu-flyout-background;
    cursor: pointer;
    text-indent: -9000px;
    &--opener {
      @include get-ico("arrow-thin-bottom");
    }
    &--closer {
      @include get-ico("arrow-thin-top");
    }
    &::before {
      width: 40px;
      height: 40px;
      font-family: $iconfont;
      text-indent: 0;
      float: left; // show despite of text-indent
      border-radius: 0;
      line-height: 40px;
    }
  }
  > a {
    margin-right: 40px; // == width .btn-opener
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  > .menu {
    display: none;
  }
  &.open {
    > .menu {
      display: block;
    }
  }
}

// Block / Pane / Custom Classes
// Classes to set to a menu wrapper

// Dropdown
.webks-menu-hover {
  @extend %menu--hover;
}
.webks-menu-dropdown {
  .menu {
    @extend %menu--dropdown;
    .menu {
      @extend %menu--dropdown-horizontal;
    }
  }
}
.webks-menu-dropdown--horizontal {
  .menu {
    @extend %menu--dropdown-horizontal;
  }
}

// Menu Specific
// -- Main Menu
.region-navigation-bar {
  // Hide on small devices (the headroom bar is permanently present at those devices)
  display: none;
  @include media-breakpoint-up(lg) {
    display: block;
  }
  .block-menu-block {
    width: 100%;
  }
}

@mixin main-menu-style($scope) {
  .menu-block-wrapper > .menu {
    @include media-breakpoint-up(lg) {
      // Breakpoint when the menu gets horizontal in this region
      @include menu--horizontal;
    }
    display: flex;
    justify-content: space-between;
    // Main Menu Styles goes here
    > li {
      text-align: left;
      > a {
        // First layer
        color: $highlight-text-color;
        font-size: $font-size-px;
        padding: $space-thin-v $thin-grid;
        &.active,
        &.active-trail {
          color: $white;
          font-weight: $font-weight-bold;
        }
      }
    }
  }
}

.navigation-bar-wrapper {
  @include main-menu-style("navigation-bar");
}

// =============================================================================
//   Headroom.js | Mobile Helpers Bar
// =============================================================================

// TODO: Die permanent variante ist so noch nicht zufriedenstellend. Was mache ich nun, wenn ich bei voller desktop ansicht XL ..whatever
//       die Bar ebenfalls dauerhaft fixieren moechte? man muesste den MQ also anders definieren koennen..?

@mixin hide-headroom-bar {
  // top: -100%;
  transform: translateY(-100%);
  margin-top: -1px; // compensate border
}
@mixin show-headroom-bar {
  transform: translateY(0%);
  top: 0;
  display: block; // Temporary test - our module sets display:none - we may have to remove it in the module css
}
@mixin headroom-bar-reserve-space {
  // Reserved Space for the Headroom bar
  padding-top: $mobile-menu-bar-height !important;
  @include media-breakpoint-up(lg) {
    padding-top: $mobile-menu-bar-height + $thin-grid !important;
  }
}

body {
  @if $headroom-docking-method == permanent {
    // Headroom bar is permanently docked to the top across all devices
    @include headroom-bar-reserve-space;
  } @else if $headroom-docking-method == triggered {
    @include media-breakpoint-down(
      breakpoint-prev($headroom-docking-permanent-breakpoint)
    ) {
      // Triggered Headroom bar switched to fixed down this breakpoint
      @include headroom-bar-reserve-space;
    }
  }
}

.headroom {
  // Just a wrapper for the modifier classes. Define default mobile bar style inside .drowl-headroom-wrapper
  @if $headroom-docking-method == triggered {
    @include media-breakpoint-down($headroom-docking-permanent-breakpoint) {
      // Triggered Headroom bar switched to fixed down this breakpoint
      @include show-headroom-bar;
    }
  }
  &--unpinned {
    // Scrolled down > Headroom hidden
    // @include transform(translateY(0)); <-- not needed?
    @if $headroom-effect == true {
      @include media-breakpoint-up($headroom-effect-breakpoint) {
        @include hide-headroom-bar;
      }
    }
  }
  &--top {
    // Top
  }
  &--not-top {
    // Not top
  }
  &--pinned {
    // Scrolled up > Headroom visible
    @if $headroom-effect == true {
      @include media-breakpoint-up($headroom-effect-breakpoint) {
        @include show-headroom-bar;
      }
    }
  }
}

.drowl-headroom-wrapper {
  // This is the initial class, to prevent fouc - use this for basic styling. The .headroom class is set via JS.
  background: $highlight;
  max-width: 100vw; // prevent overwidth if the content overflows the regular viewport width
  border-bottom: 1px solid $highlight-dark;
  width: 100% !important; // Prevent from getting overwritten
  padding: 0 !important; // Prevent from getting overwritten
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  @if $headroom-docking-method == triggered {
    @include media-breakpoint-up($headroom-docking-permanent-breakpoint) {
      display: none; // fouc protection
    }
  }
  @include transition(transform $transition-duration-headroom linear);
  &--invisible {
    // remove fouc protection but hide the bar initially
    @if $headroom-docking-method == triggered {
      @include media-breakpoint-up($headroom-docking-permanent-breakpoint) {
        display: block;
        @include hide-headroom-bar;
      }
    } @else if $headroom-docking-method == permanent {
      // Regular Main menu is visible > hide headroom bar
      // TODO: We call the same mixin ..if its tirggered or permanent ..so why do we need this if else ?
      @include media-breakpoint-up($headroom-docking-permanent-breakpoint) {
        @include hide-headroom-bar;
      }
    }
  }
  &:not(.drowl-headroom-wrapper--invisible) {
    display: block; // remove fouc protection
  }
  > .region-inner {
    max-width: $page-max-width;
    margin: 0 auto;
    padding: 5px 0;
    display: table; // flexbox fallback
    display: flex;
    justify-content: space-between;
    @include media-breakpoint-down(lg) {
      padding-left: $thin-grid;
      padding-right: $thin-grid;
    }
    > .block {
      display: table-cell; // flexbox fallback
      vertical-align: top;
      &:last-child {
        margin-left: auto;
      }
    }
    // Logo inside headbar (optional)
    .block--navbar-logo {
      max-width: 120px;
    }
  }
  // Contents
  // Main Menu
  .block-menu-block {
    width: 100%;
  }
  @if $headroom-searchblock == true {
    .block-search {
      // width:25%;
    }
    .block-menu-block {
      // width:75%;
    }
  }
  // Main Menu styles
  @include main-menu-style("headroom-bar");

  // Blocks
  .block {
    margin: 0;
  }
}

.webks-offcanvas-layer-opened .drowl-headroom-wrapper {
  @include hide-headroom-bar;
}

// Footer Menu
.block-menu-menu-footer-menu {
  @if $show-footer-menu-vertical == true {
    .menu {
      @include menu--vertical;
    }
  } @else {
    .menu {
      @include tiny-menu("horizontal");
    }
  }
}

// =============================================================================
//   Offcanvas Layer
// =============================================================================

// Shrinked Main Menu (Off Canvas)
$offcanvas-item-padding-y: 10px;
$offcanvas-item-padding-x: $mobile-grid;
$offcanvas-item-font-size: $font-size-px;
$offcanvas-item-line-height: $font-size-px + 4px;
$offcanvas-exp-item-trigger-width: ($offcanvas-item-padding-y * 2) +
  $offcanvas-item-line-height;

// Off Canvas Menu
button.webks-offcanvas-trigger {
  @include btn-mod("ghost-dark");
  @if $show-touch-menu-label == false {
    .ico-seperate {
      margin-right: 0;
      width: $icon-size-normal;
      &::before {
        font-size: $icon-size-normal;
      }
    }
    .btn-label {
      display: none;
    }
  }
}
.webks-offcanvas-layer {
  display: none;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10000;
  height: 100%;
  max-height: none !important; // Override Max-height Script (needed for normal, none offcanvas dropdowns)
  width: 90%;
  background-color: $menu-offcanvas-background;
  overflow: auto; // scrollable container
  &.open {
    display: block;
    @include animated(250ms);
    animation-name: fadeInRight;
  }
  .btn-expandable-menu-item--closer {
    background-color: $dropdown-depth-1-color;
  }
  .menu {
    position: static !important;
    .menu {
      // Depth 1
      background-color: $dropdown-depth-1-color;
      .btn-expandable-menu-item--closer {
        background-color: $dropdown-depth-2-color;
      }
      .menu {
        // Depth 2
        background-color: $dropdown-depth-2-color;
        .btn-expandable-menu-item--closer {
          background-color: $dropdown-depth-3-color;
        }
        .menu {
          // Depth 3
          background-color: $dropdown-depth-3-color;
        }
      }
    }
  }
  li {
    border-bottom: 1px solid darken($menu-offcanvas-background, 5%);
    font-size: $offcanvas-item-font-size;
    line-height: $offcanvas-item-line-height;
    a {
      color: $menu-offcanvas-color;
      padding: $offcanvas-item-padding-y $offcanvas-item-padding-x;
    }
    &.expanded {
      &.open {
        > a {
          border-bottom: 1px solid darken($menu-offcanvas-background, 5%);
        }
      }
    }
  }
  .expandable-menu-item {
    > a {
      margin-right: $offcanvas-exp-item-trigger-width;
    }
    > .btn-expandable-menu-item {
      width: $offcanvas-exp-item-trigger-width;
      max-height: $offcanvas-exp-item-trigger-width;
      &::before {
        width: $offcanvas-exp-item-trigger-width;
        height: $offcanvas-exp-item-trigger-width;
        line-height: $offcanvas-exp-item-trigger-width;
      }
    }
  }
  .menu-mlid-close {
    display: none;
  }
  // Search Block
  .block-search-form {
    border-bottom: 1px solid darken($menu-offcanvas-background, 5%);
    margin-bottom: 5px;
    padding: 10px 60px 10px $mobile-grid !important; // 60px free space for the close button || https://github.com/brigade/scss-lint/issues/809
    float: none !important;
    width: 100% !important;
    .form-submit {
      background-color: darken($menu-offcanvas-background, 5%) !important;
    }
    + .block-menu-block {
      // Remove free space if the search block exists
      padding-top: 0;
    }
  }
  .block-menu-block {
    // free space for the close button
    padding-top: 55px;
  }
  // Social Media Icons
  .block--social-media {
    margin: $space-thin-v $thin-grid 0 $thin-grid;
    text-align: center;
    padding: $space-thin-v $thin-grid;
    background: $white;
    border-radius: $border-radius;
    .ico-seperate {
      float: none;
      display: inline-block;
    }
  }
}
button.drowl-offcanvas__close-btn {
  @include btn("ghost-dark", "default", "remove", "true");
  position: absolute;
  right: 7px;
  top: 10px;
  z-index: 3;
  background-color: $menu-offcanvas-background !important;
}

@include media-breakpoint-up(md) {
  .shrinked-main-menu-triggers li a {
    text-indent: 0;
    &::before {
      display: inline-block;
      position: static;
      margin-right: 5px;
      margin-bottom: -5px;
      width: auto;
    }
  }
}

// =============================================================================
//  Pager
// =============================================================================
.pager {
  clear: both;
  margin: 1rem 0 0 0 !important;
  text-align: center;
  list-style-type: none;
  padding: 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  @media #{$large-up} {
    flex-wrap: nowrap;
  }
  .ajax-progress {
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -8px;
    margin-top: -8px;
  }
  li {
    margin: 0 5px 5px 0;
    display: inline-block;
    position: relative;
    a {
      display: block;
    }
    &:last-child {
      margin-right: 0;
    }
  }
  .pager-current {
    padding: 3px 5px;
  }
  .pager-previous {
    margin-right: auto; // align left
    flex-grow: 1;
    @media #{$large-up} {
      flex-grow: 0;
    }
  }
  .pager-next {
    margin-left: auto; // align right
    flex-grow: 1;
    @media #{$large-up} {
      flex-grow: 0;
    }
  }
  .pager-first,
  .pager-last {
    flex-grow: 1;
    @media #{$large-up} {
      flex-grow: 0;
    }
  }
  a,
  .pager-current {
    @include btn("ghost");
  }
}

// =============================================================================
//  Tabs
// =============================================================================

.nav-tabs {
  position: relative; // important!!! bootstrap-tabdrop works with offsetTop
}

// Primary (override BS Tabs)
ul.primary {
  margin-bottom: $space-thin-v;
  a {
    text-decoration: none;
  }
}

// ul.primary, <-- now BS .nav .nav-tabs
#login-links,
.ui-tabs .ui-tabs-nav,
.horizontal-tabs-list {
  border-color: $grey-light;
  border-width: 0 0 1px;
  border-style: solid;
  border-radius: 0;
  position: relative;
  padding: 0;
  list-style: none;
  background: none;
  margin: 0.5em 0 1em;
  line-height: normal;
  &::after {
    // fade out effect for the overflow scrolling behavoir
    content: " ";
    width: 15px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    @include horizontal-gradient($transparent, $white);
  }
  @include media-breakpoint-down(lg) {
    // make the container horizontally scrollable if there to many tabs (only on medium and small devices - cause we do not want big fat ugly a desktop browser scrollbar there)
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
  }
  li {
    // Undo some jquery ui styles
    border: 0 none;
    background: none;
    color: $text-color;
    float: none;
    display: inline-block;
    margin-right: 3px;
    a.active,
    a.lt-active,
    &.selected a,
    &.ui-tabs-active > a {
      position: relative;
      bottom: -1px;
      padding-top: 8px; // compensate negative amount
      color: $highlight;
      background: $white;
    }
    a {
      display: block;
      padding: 7px 10px;
      text-decoration: none;
      outline: 0;
      border-width: 1px 1px 0;
      border-style: solid;
      border-color: $grey-light;
      border-radius: 0;
      background: $grey-light;
      strong {
        font-weight: normal; // 'Bugfix' for the fieldgroup tabs, somebody thought its a good idea to capsulate it with STRONG
      }
      &:hover,
      &:focus {
        color: $text-color;
        background: $white;
      }
      &:focus {
        outline: 0;
        border-color: darken($grey-light, 8%);
      }
    }
  }
}

// -- Tabs Content Wrapper
.horizontal-tabs-panes {
  clear: both;
  padding: 10px ($grid / 2);
  background: $white;
  border: 1px solid $grey;
  .field-group-htab {
    padding: 0;
  }
  .horizontal-tabs-pane {
    > legend {
      display: none !important;
    }
    > .fieldset-wrapper {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }
  .horizontal-tab-hidden {
    display: none;
  }
}

// Secondary
ul.secondary {
  margin: 0 0 8px;
  padding: 0.55em 10px;
  border-width: 0 1px 1px;
  border-style: solid;
  line-height: normal;
  border-color: $grey-light;
  background: $white;
  @include clearfix-after;
  li {
    border-color: $grey-light;
    float: left;
    margin-right: 7px;
    padding-right: 7px;
    list-style: none;
    border-width: 0 1px 0 0;
    border-style: solid;
    &:last-child {
      border-width: 0;
    }
    a {
      display: block;
      text-decoration: none;
    }
  }
}

// Vertical tabs
div.vertical-tabs {
  margin: 1em 0;
  width: 100%;
  border: 0 none;
  box-sizing: border-box;
  ul.vertical-tabs-list {
    margin: 0;
    padding: 0;
    list-style: none;
    border-width: 0 3px 0 0;
    border-style: solid;
    border-color: $highlight;
    box-sizing: border-box;
    @include media-breakpoint-up(md) {
      width: 30%;
      float: left;
    }
    li {
      border: 0 none;
      margin-bottom: 2px;
      a {
        color: $text-color;
        text-decoration: none;
        display: block;
        padding: 10px;
        line-height: 1.1em;
        border-width: 1px 0 1px 1px;
        border-style: solid;
        border-color: $white;
        background-color: $white;
        .form-required {
          color: $white;
        }
        strong {
          font-weight: normal;
        }
        .summary {
          display: block;
          color: $grey;
        }
        &:hover {
          outline: 0;
        }
      }
      &:hover {
        border-color: $grey-light;
        background: $white;
      }
      &.selected {
        a {
          background: $highlight;
          border-color: $highlight;
          border-radius: $border-radius 0 0 $border-radius;
          .summary {
            color: lighten($highlight, 35%);
          }
        }
        strong {
          color: $white;
        }
      }
    }
  }
  .vertical-tabs-panes {
    clear: both;
    border-width: 0 0 3px;
    border-style: solid;
    border-color: $highlight;
    margin-left: -3px; // prevent double border (we use this workaround to get a 100% height border)
    box-sizing: border-box;
    @include media-breakpoint-up(md) {
      clear: none;
      float: left;
      width: 70%;
      border-width: 0 0 0 3px;
    }
  }
  .vertical-tabs-pane {
    margin: 0;
    border: 0 none;
    box-sizing: border-box;
    > legend {
      display: none;
    }
  }
}

// Action Links
ul.action-links {
  margin: 10px 0 $grid-vertical;
  padding: 0;
  list-style: none;
  > li {
    > a {
      @include btn(ghost, "xs");
    }
  }
}

// jQuery Tabs
.ui-tabs {
  border: 0 none;
}
